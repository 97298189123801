/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getImage, GatsbyImage} from 'gatsby-plugin-image';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components), {Box} = _components;
  if (!Box) _missingMdxReference("Box", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Historiallinen tuotto ei ole tae tulevasta, mutta historian datasta löytyvät trendit ovat silti usein ihan hyviä lähtökohtia ennusteille ja skenaariotyöskentelylle. Trendiviiva on usein helppo piirtää Excelin laskemana tai vaikka ihan käsivaralta. Trendejä voi hakea yrityksen sisäisestä datasta, laajemmin markkinoiden muutoksista tai vaikka globaaleista muutoksista."), "\n", React.createElement(_components.p, null, "Mutta jatkuuko trendi tai mitkä asiat ylipäätään vaikuttavat trendiin. Mitkä ovat trendin ajurit ja mahdollistajat? Mikä aiheuttaa hidastaa tai suorastaan vastustaa trendiä."), "\n", React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(GatsbyImage, {
    image: getImage(props.data.mdx.frontmatter.images[0]),
    alt: ""
  })), "\n", React.createElement(_components.p, null, "SWOT on monelle tuttu viitekehys vahvuuksien (Strength), heikkouksien (Weakness), mahdollisuuksien (Opportunities) ja uhkien (Threats) reflektointiin. Adam Gordonin DEFT-malli on trendien SWOT. Se on järjestelmällinen ja kattava tapa analysoida trendiin vaikuttavia voimia. Tunnistamalla ja tutkimalla trendin ajureita, mahdollistajia, kitkaa ja estäjiä voidaan löytää oivalluksia suuntauksen dynamiikasta ja parempi pohja sen tulevaisuuden ennustamiselle."), "\n", React.createElement(_components.p, null, "DEFT -malli jakaa trendiin vaikuttavat voimat neljään."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Ajurit (drivers) ovat keskeiset voimat tai tekijät, jotka edistävät havaitun trendin kehittymistä tai kasvua."), "\n", React.createElement(_components.li, null, "Mahdollistajat (enablers) viittaavat trendiä tukeviin elementteihin tai olosuhteisiin, jotka helpottavat sen etenemistä ja kestävyyttä."), "\n", React.createElement(_components.li, null, "Kitka (friction) edustaa esteitä, haasteita tai rajoituksia, jotka haittaavat suuntauksen etenemistä tai omaksumista."), "\n", React.createElement(_components.li, null, "Estäjät (turners/blockers) ovat potentiaalisia häiriövoimia tai tapahtumia, jotka voivat muuttaa suuntauksen suuntaa tai kehityskulkua."), "\n"), "\n", React.createElement(_components.p, null, "On tärkeä huomata, että ero ajurin ja mahdollistajan, ja kitkan ja estäjän välillä ei aina ole selvä. Joskus on tärkeä paneutua tarkemmin asioiden syy-seuraussuhteiden selvittämiseen. Joskus ero voi myös olla puhtaasti tulkintakysymys. Aivan kuten SWOT-analyysissäkin, trendiin vaikuttavien voimien arvioinnissakin täytyy pitää mielessä, että tärkeintä on prosessi, ei lopputulos."), "\n", React.createElement(_components.p, null, "Lue lisää ", React.createElement(_components.a, {
    href: "https://www.researchgate.net/publication/46533831_A_DEFT_Approach_to_Trend-Based_Foresight"
  }, "https://www.researchgate.net/publication/46533831_A_DEFT_Approach_to_Trend-Based_Foresight")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
